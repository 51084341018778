import {
  createAction,
  buildPaginationRequestParams,
  SECTION_CATEGORY,
  ENTITY_TYPE_POSTS,
  BLOG_HEADER_CURSOR,
  urijs,
  getCategoryIds,
  getCategoryBySlug,
  resolveId,
} from '@wix/communities-blog-client-common';

import { fetchCategory } from '../../feed-page/actions/fetch-category';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import {
  getPageSize,
  getCursor,
  getShowPagination,
  getLazyPaginationParams,
} from '../selectors/pagination-selectors';
import { getHeader } from '../services/get-header';
import { getTotalResults } from '../services/pagination';
import { normalizePosts } from '../services/post-utils';

export const FETCH_CATEGORY_POSTS_REQUEST = 'categoryPosts/FETCH_REQUEST';
export const FETCH_CATEGORY_POSTS_SUCCESS = 'categoryPosts/FETCH_SUCCESS';
export const FETCH_CATEGORY_POSTS_FAILURE = 'categoryPosts/FETCH_FAILURE';

export const fetchCategoryPostsRequest = createAction(
  FETCH_CATEGORY_POSTS_REQUEST,
);
export const fetchCategoryPostsSuccess = createAction(
  FETCH_CATEGORY_POSTS_SUCCESS,
  (payload) => payload,
  (payload, meta) => meta,
);
export const fetchCategoryPostsFailure = createAction(
  FETCH_CATEGORY_POSTS_FAILURE,
  (payload) => payload,
  (payload, meta) => meta,
);

export const fetchCategoryPosts =
  ({
    categoryId,
    page = 1,
    pageSize: defaultPageSize,
    featuredOnly,
    excludeContent,
    preFetch,
    useLazyPagination = false,
  }) =>
  (dispatch, getState, { request }) => {
    dispatch(fetchCategoryPostsRequest({ categoryId, page }));

    const state = getState();
    const showPagination = getShowPagination(state, SECTION_CATEGORY);
    const categoriesCursor = getCursor(state, ENTITY_TYPE_POSTS);
    const pageSize = getPageSize(state, {
      overrideSettingsPageSize: defaultPageSize,
      section: SECTION_CATEGORY,
    });

    const lazyPaginationParams = useLazyPagination
      ? getLazyPaginationParams({
          state: getState(),
          section: SECTION_CATEGORY,
          page,
        })
      : undefined;
    const paginationParams =
      lazyPaginationParams ||
      buildPaginationRequestParams(
        page,
        pageSize,
        showPagination ? null : categoriesCursor,
      );

    const promise = request(
      urijs('/_api/posts').query({
        categoryIds: categoryId,
        featuredOnly,
        excludeContent,
        ...paginationParams,
      }),
      {
        parseHeaders: true,
      },
    );

    if (preFetch) {
      return promise;
    }

    return completeFetchCategoryPosts({ categoryId, page, pageSize }, promise)(
      dispatch,
      getState,
    );
  };

export const completeFetchCategoryPosts =
  ({ categoryId, slug, page, pageSize }, promise) =>
  async (dispatch, getState) => {
    try {
      const { body = [], headers = {} } = await promise;

      categoryId = categoryId || resolveId(getCategoryBySlug(getState(), slug));
      dispatch(
        fetchCategoryPostsSuccess(
          normalizePosts({
            state: getState(),
            posts: body,
            blogCategoryIds: getCategoryIds(getState()).concat(categoryId),
          }),
          {
            categoryId,
            page,
            entityCount: getTotalResults(headers),
            pageSize,
            cursor: getHeader(headers, BLOG_HEADER_CURSOR),
          },
        ),
      );
    } catch ({ status }) {
      dispatch(
        fetchCategoryPostsFailure(
          { error: { status }, categoryId, page },
          { categoryId },
        ),
      );
    }
    return promise;
  };

export const fetchCategoryPostsBySlug =
  ({ slug, page, excludeContent, preFetch, useLazyPagination }) =>
  async (dispatch, getState, { request }) => {
    const category =
      getCategoryBySlug(getState(), slug) ||
      (await fetchCategory(slug)(dispatch, getState, { request }));

    return fetchCategoryPosts({
      categoryId: resolveId(category),
      page,
      excludeContent,
      preFetch,
      useLazyPagination,
    })(dispatch, getState, { request });
  };

export const fetchCategoryPostsPromisified = createPromisifiedAction(
  fetchCategoryPosts,
  () => null,
  (response) => response.status,
);
