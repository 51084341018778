import {
  getPostFeedPage,
  getPostFeedMetadataPage,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import {
  Metrics,
  Post as PostNullable,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import {
  ENTITY_TYPE_POSTS,
  fetchAppDataSuccess,
  fetchTagsSuccess,
  fetchTPASettingsSuccess,
  getCategoryIds,
  getDefaultSiteLocale,
  getLocales,
  SECTION_HOMEPAGE,
} from '@wix/communities-blog-client-common';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import {
  fetchFeedPostsFailure,
  fetchFeedPostsRequest,
  fetchFeedPostsSuccess,
} from '../../common/actions/fetch-feed-posts';

import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getPageSize } from '../../common/selectors/pagination-selectors';
import { normalizePosts } from '../../common/services/post-utils';
import { setUser } from '../../common/store/auth/set-user';
import { fetchCategoriesSuccess } from '../../common/store/categories/fetch-categories';
import { fetchPaywallBanner } from '../../common/store/paywall/paywall-actions';
import { fetchTranslationsSuccess } from '../../common/store/translations/translations-actions';
import { DeepRequired } from '../../common/types';
import { FeedPageThunkAction } from '../types';

type Post = DeepRequired<PostNullable>;

type FetchFeedRenderModelParams = {
  includeContent: boolean;
  includeInitialPageData: boolean;
  language: string;
  page: number;
  pageSize?: number;
  section: typeof SECTION_HOMEPAGE;
  translationsName?: string;
};

export const fetchFeedRenderModel =
  ({
    includeContent,
    includeInitialPageData,
    language,
    page = 1,
    pageSize: defaultPageSize,
    section,
    translationsName,
  }: FetchFeedRenderModelParams): FeedPageThunkAction =>
  async (dispatch, getState, { httpClient }) => {
    dispatch(fetchFeedPostsRequest({ entityType: ENTITY_TYPE_POSTS, page }));

    try {
      const state = getState();
      const locale = getQueryLocale(state);
      const languageCode =
        locale ||
        language ||
        (getLocales(state) && getDefaultSiteLocale(state)?.id) ||
        null;
      const pageSize = defaultPageSize ?? getPageSize(state, { section });

      const [feedResponse, metadataResponse] = await Promise.all([
        httpClient
          .request(
            getPostFeedPage({
              includeContent,
              includeInitialPageData,
              languageCode,
              locale,
              page,
              pageSize,
              translationsName,
            }),
          )
          .then((r) => r.data as DeepRequired<typeof r.data>),
        httpClient
          .request(
            getPostFeedMetadataPage({
              page,
              pageSize,
              locale,
            }),
          )
          .then((r) => r.data as DeepRequired<typeof r.data>),
      ]);

      const {
        currentUser,
        posts,
        categories,
        tags,
        settings,
        translations,
        appData,
      } = feedResponse.postFeedPage;

      // TODO: refactor consumer to fallback on label when menuLabel is not available
      categories.forEach((x) => {
        (x as any).menuLabel = x.label;
      });

      const postsWithMetadata = enhancePostsWithMetadata(
        feedResponse.postFeedPage.posts.posts,
        metadataResponse.postFeedMetadataPage.postMetrics,
      );

      return Promise.all(
        includeInitialPageData
          ? [
              currentUser?.id
                ? dispatch(setUser(currentUser))
                : Promise.resolve(),
              dispatch(fetchTPASettingsSuccess(settings)),
              dispatch(fetchCategoriesSuccess(categories)),
              dispatch(fetchAppDataSuccess(appData)),
              dispatch(fetchTranslationsSuccess(translations)),
              dispatch(fetchTagsSuccess(tags)),
              dispatch(
                fetchFeedPostsSuccess(
                  normalizePosts({
                    state: { categories, translations },
                    posts: postsWithMetadata,
                    blogCategoryIds: getCategoryIds({ categories }),
                    origin: '/v3/posts',
                  }),
                  {
                    page,
                    entityType: ENTITY_TYPE_POSTS,
                    entityCount: posts.metaData?.total,
                    pageSize,
                  },
                ),
              ),
              dispatch(fetchPaywallBanner()),
            ]
          : [
              dispatch(
                fetchFeedPostsSuccess(
                  normalizePosts({
                    state,
                    blogCategoryIds: getCategoryIds(state),
                    posts: postsWithMetadata,
                    origin: '/v3/posts',
                  }),
                  {
                    page,
                    entityType: ENTITY_TYPE_POSTS,
                    entityCount: posts.metaData?.total,
                    pageSize,
                  },
                ),
              ),
            ],
      );
    } catch (_) {
      dispatch(fetchFeedPostsFailure({ entityType: ENTITY_TYPE_POSTS, page }));
    }
  };

const enhancePostsWithMetadata = (
  posts: Post[],
  postMetricsMap: Record<string, DeepRequired<Metrics>>,
): Post[] => {
  const defaultMetrics: DeepRequired<Metrics> = {
    averageRating: 0,
    comments: 0,
    likes: 0,
    totalRatings: 0,
    views: 0,
  };

  return posts.map((p) => ({
    ...p,
    metrics: postMetricsMap[p.id] || defaultMetrics,
  }));
};

export const fetchFeedRenderModelPromisified = createPromisifiedAction(
  fetchFeedRenderModel,
  () => null,
  (response) => response.status,
);
